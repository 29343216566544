import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { navigate } from 'gatsby'

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo
        title={`Error 404`}
        description={`Halaman Atau Tips Judi Online Yang Anda Cari Tidak Ditemukan Atau Rusak`}
        url={`https://gubelinlab.com/404/`}
        image={`https://gubelinlab.com/images/og_image.jpg`}
      />
      <div className="relative bg-white overflow-hidden">
        <div
          className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
          aria-hidden="true"
        >
          <div className="absolute bottom-0 left-0 right-0 h-10 bg-gradient-to-t from-white to--gray-50 hidden sm:block" />
          <div className="relative h-full max-w-7xl mx-auto">
            <svg
              className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="grid-dotted"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <circle
                    cx="2"
                    cy="2"
                    r="2"
                    className="text-gray-200"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#grid-dotted)" />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <rect width="404" height="784" fill="url(#grid-dotted)" />
            </svg>
          </div>
        </div>
        <div className="relative mt-16 mx-auto max-w-7xl px-4 sm:mt-24 pb-16 sm:pb-24">
          <div className="text-center">
            .
            <p className="leading-6 text-purple-600 font-semibold tracking-wide uppercase">
              Gubelinlab
            </p>
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block text-rose-600 xl:inline">Error 404</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Halaman Atau Tips Judi Online Yang Anda Cari Tidak Ditemukan Atau Rusak
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-xl shadow">
                <button
                  type="button"
                  onClick={() => navigate('/')}
                  className="w-full sm:w-auto flex-none bg-gray-50 hover:bg-white text-gray-900 text-lg leading-6 font-semibold py-4 px-6 border border-transparent rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-rose-500 focus:outline-none transition-colors duration-200"
                >
                  Kembali Ke Beranda
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
